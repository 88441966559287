import moment from 'moment';
import { Form } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';
import content from './content';
import handlers from './handlers';

const infoTab: Form = {
    id: 'infoTab',
    type: ViewTypes.Form,
    fields: {
        uniquePlayers: '',
        numberOfCapsCollected: '',
        usersWithEightOrMoreCaps: 0,
        numberOfPlayersWith1Cap: '',
        numberOfPlayersWith2Caps: '',
        numberOfPlayersWith3Caps: '',
        numberOfPlayersWith4Caps: '',
        numberOfPlayersWith5Caps: '',
        numberOfPlayersWith6Caps: '',
        numberOfPlayersWith7Caps: '',
        numberOfPlayersWith8Caps: '',
        capStats: [],
        endDate: moment().format('YYYY-MM-DD'),
        startDate: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    },
    content,
    handlers,
};

export default infoTab;
