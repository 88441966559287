import moment from 'moment';
import {
    AppContext,
    FormFunctionGetHandlers,
    FormHandlers,
} from '../../../../../core/components/form/context/types';
import apiClient from '../../../../../core/requests/api';

const convertDateFilterToQuery = (
    field: string,
    { startDate, endDate }: any
): string => {
    if (!startDate && !endDate) {
        return '';
    }

    const startMoment = moment(startDate);
    const endMoment = moment(endDate);

    if (startDate && endDate) {
        return `${field}:[${startMoment.toISOString()} TO ${endMoment.toISOString()}]`;
    }

    if (startDate) {
        return `${field}:[${startMoment.toISOString()} TO *]`;
    }

    return `${field}:[* TO ${endMoment.toISOString()}]`;
};

const handlers: FormFunctionGetHandlers = function (
    this: AppContext
): FormHandlers {
    const loadTopUsers = async (date: Date) => {
        this.form.topUsers = [];

        var startDate = new Date(date);
        startDate.setHours(0, 0, 0, 0);

        var endDate = new Date(date);
        endDate.setHours(23, 59, 59, 999);

        const dateFilter = convertDateFilterToQuery('createdAt', {
            startDate,
            endDate,
        });
        let q = '';

        if (dateFilter) {
            q = dateFilter;
        }

        const queryParams = `sort=createdAt:asc&q=${q}&size=500`;
        const response = await apiClient.callSearchRequest(
            'cocacola',
            'findthebottlecap',
            queryParams
        );

        console.log(response);

        this.form.topUsers = response.hits.hits.map((item, index) => {
            return { index, id: item._id, ...item._source };
        });
    };

    const loadCapCollectionStats = async () => {
        try {
            const { startDate, endDate } = this.form;
            let q = convertDateFilterToQuery('createdAt', {
                startDate,
                endDate,
            });

            // Use a composite aggregation approach
            const aggs = {
                aggs: {
                    // First get total caps collected
                    total_caps: {
                        value_count: {
                            field: 'cap',
                        },
                    },
                    // Get unique users overall
                    unique_users_total: {
                        cardinality: {
                            field: 'username',
                        },
                    },
                    // Use significant terms to find the most significant capIds
                    significant_caps: {
                        significant_terms: {
                            field: 'cap',
                            size: 30,
                        },
                        aggs: {
                            cap_count: {
                                cardinality: {
                                    field: 'cap',
                                },
                            },
                            users_with_this_cap: {
                                value_count: {
                                    field: 'username',
                                },
                            },
                        },
                    },
                },
            };

            const queryParams = `size=0&aggs=${JSON.stringify(aggs)}&q=${q}`;
            const response = await apiClient.callSearchRequest(
                'cocacola',
                'findthebottlecap',
                queryParams
            );
            // Reset statistics fields
            this.form.uniquePlayers = 0;
            this.form.numberOfCapsCollected = 0;
            this.form.usersWithEightOrMoreCaps = 0;
            this.form.capStats = [];

            // Set the total statistics
            this.form.uniquePlayers =
                response.aggregations.unique_users_total.value;
            this.form.numberOfCapsCollected =
                response.aggregations.total_caps.value;

            // Process each significant cap
            const sigCapBuckets =
                response.aggregations.significant_caps.buckets || [];

            const capStats: any[] = [];

            sigCapBuckets.forEach((bucket: any) => {
                const capId = bucket.key;
                const uniqueUsersCount = bucket.users_with_this_cap.value;
                const capCount = bucket.doc_count;
                capStats.push({
                    capId,
                    uniqueUsers: uniqueUsersCount,
                    totalCollected: capCount,
                    significance: bucket.score, // Add significance score for additional insight
                });
            });

            // Sort by significance score
            capStats.sort((a: any, b: any) => b.significance - a.significance);

            this.form.capStats = capStats;

            // Alternative approach for counting users with 8+ caps
            // We'll make a separate query using terms aggregation with minimum doc count
            const userCapsCountAggs = {
                aggs: {
                    user_caps: {
                        terms: {
                            field: 'username',
                            size: 10000,
                            min_doc_count: 8, // Only include users with at least 8 documents
                        },
                        aggs: {
                            distinct_caps: {
                                cardinality: {
                                    field: 'cap',
                                },
                            },
                        },
                    },
                },
            };

            const userCapsCountQueryParams = `size=0&aggs=${JSON.stringify(
                userCapsCountAggs
            )}&q=${q}`;
            const userCapsCountResponse = await apiClient.callSearchRequest(
                'cocacola',
                'findthebottlecap',
                userCapsCountQueryParams
            );

            // Filter to only count users with 8+ distinct caps
            const userBuckets =
                userCapsCountResponse.aggregations.user_caps.buckets || [];
            let usersWithEightOrMoreCaps = 0;

            userBuckets.forEach((bucket: any) => {
                if (bucket.distinct_caps.value >= 8) {
                    usersWithEightOrMoreCaps++;
                }
            });

            this.form.usersWithEightOrMoreCaps = usersWithEightOrMoreCaps;
        } catch (error) {
            console.error('Error loading cap collection statistics:', error);
            // Set default values in case of error
            this.form.uniquePlayers = 0;
            this.form.numberOfCapsCollected = 0;
            this.form.usersWithEightOrMoreCaps = 0;
            this.form.capStats = [];
        }
    };

    const loadData = () => {
        loadCapCollectionStats();
    };

    const onBeforeOpen = async () => loadData();

    const onChangeStartDate = (newStartDate: string) => {
        this.form.startDate = moment(newStartDate).format('YYYY-MM-DD');
        loadData();
    };

    const onChangeEndDate = async (newEndDate: string) => {
        this.form.endDate = moment(newEndDate).format('YYYY-MM-DD');
        loadData();
    };

    const onChangeDaySelection = async (item: any) => {
        loadTopUsers(item);
    };

    return {
        onBeforeOpen,
        onChangeStartDate,
        onChangeEndDate,
        onChangeDaySelection,
    };
};

export default handlers;
