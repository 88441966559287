import FormConfig from '../../../../core/components/form/types';
import ViewTypes from '../../../../core/components/form/ViewTypes';
import info from './info';

const form: FormConfig = {
    id: 'coacola',
    title: 'CocaCola Dashboard',
    fields: {
        activeTabId: 'info',
    },
    content: {
        id: 'rootGroup',
        type: ViewTypes.TabsGroup,
        style: { height: '100%' },
        activeTabIdSource: 'activeTabId',
        tabs: [
            {
                id: 'info',
                type: ViewTypes.TabPage,
                title: 'נתונים',
                content: info,
            },
        ],
    },
};

export default form;
