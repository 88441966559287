import { VStack } from '../../../../../core/components/form/context/types';
import ViewTypes from '../../../../../core/components/form/ViewTypes';

const content: VStack = {
    id: 'rootGroup',
    type: ViewTypes.VStack,
    items: [
        {
            id: 'filterGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'startDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך התחלה',
                    showClearButton: true,
                    dataSource: 'startDate',
                    onChangeValue: 'onChangeStartDate',
                },
                {
                    id: 'endDate',
                    type: ViewTypes.DateInput,
                    label: 'תאריך סוף',
                    showClearButton: true,
                    dataSource: 'endDate',
                    onChangeValue: 'onChangeEndDate',
                },
            ],
        },
        {
            id: 'totalStatsGroup',
            type: ViewTypes.HStack,
            style: { marginBottom: 30 },
            items: [
                {
                    id: 'uniquePlayers',
                    type: ViewTypes.Counter,
                    label: 'מספר שחקנים יחודיים',
                    style: { marginLeft: 10 },
                    dataSource: 'uniquePlayers',
                },
                {
                    id: 'numberOfCapsCollected',
                    type: ViewTypes.Counter,
                    label: 'פקקים שנאספו סה"כ',
                    style: { marginLeft: 10 },
                    dataSource: 'numberOfCapsCollected',
                },
                {
                    id: 'usersWithEightOrMoreCaps',
                    type: ViewTypes.Counter,
                    label: 'שחקנים עם 8+ פקקים',
                    style: { marginLeft: 10, backgroundColor: '#f0f8ff' }, // Light blue background to highlight this stat
                    dataSource: 'usersWithEightOrMoreCaps',
                },
            ],
        },
        {
            id: 'capStatsTitle',
            type: ViewTypes.Text,
            style: {
                fontSize: 24,
                fontWeight: 'bold',
                marginBottom: 15,
                direction: 'rtl',
                textAlign: 'right',
            },
            value: 'מספר שחקנים לפי פקק:',
        },
        {
            id: 'capStatsTable',
            type: ViewTypes.MemoryTable,
            isSelectable: false,
            dataSource: 'capStats',
            pageSize: 20,
            columns: [
                {
                    id: 'capId',
                    title: 'מזהה פקק',
                    type: 'text',
                    fieldName: 'capId',
                },
                {
                    id: 'uniqueUsers',
                    title: 'מספר שחקנים',
                    type: 'number',
                    fieldName: 'uniqueUsers',
                },
                {
                    id: 'totalCollected',
                    title: 'סה"כ נאספו',
                    type: 'number',
                    fieldName: 'totalCollected',
                },
                {
                    id: 'significance',
                    title: 'משמעות',
                    type: 'number',
                    fieldName: 'significance',
                },
            ],
        },
    ],
};

export default content;
