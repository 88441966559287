import { CustomAppConfig } from '../../core/config-builder/types';
import alproducts from './alproducts';
import caveret from './caveret';
import cbc from './cbc';
import checklist from './checklist';
import cocacola from './cocacola';
import galitz from './galitz';
import ibbls from './ibbls';
import jobsearch from './jobsearch';
import kidum from './kidum';
import knowledgebase from './knowledgebase';
import lostandfound from './lostandfound';
import lostandfoundv2 from './lostandfoundv2';
import lottery from './lottery';
import magazine from './magazine';
import multifileupload from './multifileupload';
import office from './office';
import pages from './pages';
import pazamshop from './pazamshop';
import pazamtv from './pazamtv';
import pazamweb from './pazamweb';
import photozone from './photozone';
import policeradar from './policeradar';
import ranks from './ranks';
import saveisrael from './saveisrael';
import shakuf from './shakuf';
import smirnoff from './smirnoff';
import songvote from './songvote';
import testsite from './testsite';
import travelagent from './travelagent';
import wiki from './wiki';

const appConfig: CustomAppConfig = {
    projectTitle: 'Miniapps Admin Management',
    pages,
    sites: {
        caveret,
        cbc,
        lostandfound,
        lottery,
        magazine,
        pazamtv,
        songvote,
        travelagent,
        policeradar,
        alproducts,
        office,
        saveisrael,
        testsite,
        ranks,
        pazamweb,
        kidum,
        pazamshop,
        shakuf,
        galitz,
        jobsearch,
        multifileupload,
        smirnoff,
        wiki,
        lostandfoundv2,
        checklist,
        knowledgebase,
        ibbls,
        photozone,
        cocacola,
    },

    params: {
        landingsAssetsUrl:
            'https://landings.pazamapp.com/files/backoffice/landings/',
        landingsBaseUrl: 'https://landings.pazamapp.com',
        baseApiUrl: 'https://miniadmin.pazamapp.com/v1',
        googleMapApiKey: 'AIzaSyCjAryGYgt8TDnvRE2rnPgwhZP0kBlJH00',
    },
};

export default appConfig;
