import { useEffect, useState } from 'react';
import apiClient from '../../../../core/requests/api';

const getNumberOfVotes = async (photoId?: string) => {
    const queryParams = `size=0&q=photoId:"${photoId}" AND vote:1`;
    const response: any = await apiClient.callSearchRequest(
        'photozone',
        'vote',
        queryParams
    );

    return response.hits.total;
};

const VoteCell: any = ({ item }: { item: { _id: string } }) => {
    const [votes, setVotes] = useState<number | null>(null);

    useEffect(() => {
        const fetchVotes = async () => {
            const totalVotes = await getNumberOfVotes(item._id);
            setVotes(totalVotes);
        };

        fetchVotes();
    }, [item._id]);

    return <div>{votes !== null ? votes : 'Loading...'}</div>;
};

export { VoteCell };
