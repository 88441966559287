/**
 *
 *
 * 1. createdAt desc as default filter
 * 2. total votes for each photo
 */

import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';

const item: ItemFormConfig = {
    title: 'צלמים',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
        },
    },
};

export default item;
