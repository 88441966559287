/**
 *
 *
 * 1. createdAt desc as default filter
 * 2. total votes for each photo
 */

import { CustomSiteConfig } from '../../../core/config-builder/types';
import photo from './photo';
import photographer from './photographer';
import vote from './vote';

const photozone: CustomSiteConfig = {
    title: 'עדשה צבאית',
    endpoints: {
        photo,
        photographer,
        vote,
    },
};

export default photozone;
