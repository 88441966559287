/**
 *
 *
 * 1. createdAt desc as default filter
 * 2. total votes for each photo
 */

import { ItemFormConfig } from '../../../../core/config-builder/forms/item/types';
import { VoteCell } from './handlers';

const item: ItemFormConfig = {
    title: 'תמונות',
    forms: {
        list: {
            // content of the list form
            defaultUrlQueryParams: {
                sort: [
                    {
                        columnId: 'createdAt',
                        direction: 'desc',
                    },
                ],
            },
            columnsOrder: [
                'active',
                'video',
                'photo',
                'creditName',
                'creditHandle',
                'photographerId',
                'totalVotes',
                'createdAt',
            ],
            columns: {
                active: {
                    title: 'פעיל',
                },
                video: {
                    title: 'סרטון',
                },
                photo: {
                    title: 'תמונה',
                },
                creditName: {
                    title: 'שם צלם שמופיע',
                },
                creditHandle: {
                    title: 'אינסטגרם',
                },
                photographerId: {
                    title: 'צלם',
                },
                totalVotes: {
                    id: 'totalVotes',
                    type: 'number',
                    title: 'הצבעות',
                    CellComponent: VoteCell,
                },
                createdAt: {
                    title: 'תאריך יצירה',
                },
            },
        },
    },
};

export default item;
